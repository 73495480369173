<template>
  <div>
    <v-dialog
      v-model="show"
      :overlay-opacity="!$vuetify.breakpoint.mobile ? '0.45' : '0.6'"
      transition="slide-y-transition"
      content-class="rounded-lg"
      overlay-color="black"
      width="550"
      scrollable
      persistent>
      <v-card
        class="about-dialog"
        rounded="lg"
        flat>
        <v-card-title
          class="font-weight-semibold is-close">
          Acerca de
          <v-btn
            @click="toggle"
            icon>
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text
          class="text-center pb-6">
          <div
            class="brand d-flex justify-center align-center mb-3">
            <span
              :class="['brand--icon', !$vuetify.theme.dark ? 'primary--text' : 'grey--text text--lighten-4']">
              <v-img
                :src="!$vuetify.theme.dark ? APP_IMG_BRAND + 'pach.svg' : APP_IMG_BRAND + 'pach-light.svg'"
                class="mx-auto"
                height="50px"
                width="95px"
                contain>
              </v-img>
            </span>
            <!-- <v-divider
              class="mx-5"
              vertical>
            </v-divider>
            <span
              :class="['brand--text text-h5 mt-1', !$vuetify.theme.dark ? 'text--primary' : 'grey--text text--lighten-4']">
              Supervisión
            </span> -->
          </div>
          <p
            class="mb-4">
            <span
              class="d-block grey--text text--darken-2 mb-4">
              Versión: {{ VERSION }}
            </span>
            <span
              class="d-block text--primary font-weight-medium">
              ¿Quíere dejar algún comentario o ha encontrado algún error? Comuníquenoslo
            </span>
          </p>
          <v-btn
            @click="toggle"
            class="rounded-lg mb-10"
            href="#/help/feedback"
            depressed
            large>
            Enviar comentarios
          </v-btn>
          <p
            class="text-body-2 grey--text text--darken-2 mb-0">
            Copyright &copy; 2022 PACH Administración, SA de CV.
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Mixins
import { defines } from '@/mixins/config'

export default {
  name: 'AboutComponent',
  mixins: [defines],
  components: { },
  data: () => ({
    show: false
  }),
  methods: {
    toggle () {
      this.show = !this.show
    }
  }
}

</script>
