// VeeValidate
import { required, required_if, digits, email, numeric, integer, double, min_value, min, max, regex, mimes, size } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('digits', {
  ...digits,
  message: '{_field_} necesita ser de {length} dígitos. ({_value_}) '
})

extend('required', {
  ...required,
  message: '{_field_} no puede quedar vacío' // '{_field_} ...'
})

extend('min', {
  ...min,
  message: '{_field_} no puede ser menor que {length} caracteres'
})

extend('max', {
  ...max,
  message: '{_field_} no puede ser mayor que {length} caracteres'
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} no coincide con {regex}'
})

extend('email', {
  ...email,
  message: 'No es una dirección de correo válida'
})

extend('numeric', {
  ...numeric,
  message: '{_field_} debe ser un número o una secuencia de números enteros'
})

extend('integer', {
  ...integer,
  message: '{_field_} debe ser un número entero'
})

extend('double', {
  ...double,
  message: '{_field_} debe ser un valor decimal'
})

extend('required_if', {
  ...required_if,
  message: '{_field_} no puede quedar vacío'
})

extend('mimes_img', {
  ...mimes,
  message: '{_field_} solamente acepta archivos de tipo imagen'
})

extend('size', {
  ...size,
  message: '{_field_} debe tener un peso menor que {size} kilobytes'
})

extend('min_value', {
  ...min_value,
  message: '{_field_} debe ser mayor que {min}'
})

export default {
  components: { ValidationObserver, ValidationProvider }
}
