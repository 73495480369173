import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.css'
import { es } from 'vuetify/lib/locale'
// import '@mdi/font/css/materialdesignicons.css'
// import colors from 'vuetify/lib/util/colors'
// import 'vuetify/lib'

// FontAwesome
// import '@/assets/css/fonts/fontawesome/all.css'

// Material Icons
// import '@/assets/css/fonts/material-icons/all.css'

Vue.use(Vuetify)

const opts = {
  lang: {
    current: 'es',
    locales: { es }
  },
  rtl: false,
  breakpoint: {
    // thresholds: {
    //   xs: 600,
    //   sm: 960,
    //   md: 980,
    //   lg: 1140,
    //   xl: 1500
    // },
    mobileBreakpoint: 'sm'
  },
  icons: {
    iconfont: 'material-icons',
    values: {
      complete: 'done',
      cancel: 'clear',
      close: 'close',
      delete: 'cancel',
      clear: 'clear',
      success: 'check_circle',
      info: 'info',
      warning: 'warning',
      error: 'error',
      prev: 'navigate_before',
      next: 'navigate_next',
      checkboxOn: 'check_box',
      checkboxOff: 'check_box_outline_blank',
      checkboxIndeterminate: 'indeterminate_check_box',
      delimiter: 'circle',
      sort: 'filter_list',
      expand: 'expand_more',
      menu: 'menu',
      subgroup: 'expand_more',
      dropdown: 'arrow_drop_down',
      radioOn: 'radio_button_checked',
      radioOff: 'radio_button_unchecked',
      edit: 'edit',
      ratingEmpty: 'star_outline',
      ratingFull: 'star',
      ratingHalf: 'star',
      loading: 'refresh',
      first: 'first_page',
      last: 'last_page',
      unfold: 'unfold_more',
      file: 'attach_file'
    }
  },
  theme: {
    dark: false,
    themes: {
      light: {
        // Basic colors
        primary: '#1867C0', // #1867C0 #103996
        primaryText: '#2196f3',
        accent: '#5847b6', // #6555C0 #5e4dbc #3949AB
        secondary: '#e91e63',
        body: '#1e1e1e',
        anchor: '#0068ca',

        // Basic Colors Light
        primaryLight: '#CAE6FC',
        primaryLightAccent: '#528bc1',
        accentLight: '#E5DFFB',
        secondaryLight: '#d1d7dF',
        secondaryLightAccent: '#d1d7df',
        anchorLight: '#0068ca',

        // Basic Colors Accent
        primaryDark: '#003097', // #0a439a
        greyAccent: '#e8eaed',

        // Alerts
        success: '#00C8B5', // #00C8B5
        warning: '#FFB300', // #FFB300
        danger: '#EC2E53', // #EC2E53
        error: '#EC2E53', // #EC2E53
        info: '#006c9c', // #0092E8 #0288D1

        // Alerts Text
        successText: '#00c8b5',
        warningText: '#ffab00',
        dangerText: '#d32f2f',
        errorText: '#d32f2f',
        infoText: '#01579b',

        // Alerts Light
        successLight: '#dcf6ee',
        warningLight: '#faf0dd',
        dangerLight: '#ffeff2',
        errorLight: '#ffe8ec',
        infoLight: '#c3e7ff',

        // Colors
        black: '#010101',
        blueDark: '#202124',

        // Backgrounds
        dark: '#070915', // #070915 #040f2a
        darkGrey: '#070b10',
        darkAccent: '#253756',

        gray: '#f0f0f0', // #f1f3f4
        grayAccent: '#eff3f5',

        // Backgrounds Light
        darkLight: '#0e111f', // #0e111f #061437
        grayLight: '#F4F5F7',
        grayAccentLight: '#eff3f5',
        neutralGrayLight: '#9ba6c1',
        darkAccentLight: '#253756'
      },
      dark: {
        // Basic colors
        primary: '#1867C0', // #1867C0 #103996
        primaryText: '#2196f3',
        accent: '#5847b6', // #6555C0 #5e4dbc #3949AB
        secondary: '#e91e63',
        body: '#1e1e1e',
        anchor: '#0068ca',

        // Basic Colors Light
        primaryLight: '#CAE6FC',
        primaryLightAccent: '#528bc1',
        accentLight: '#E5DFFB',
        secondaryLight: '#d1d7dF',
        secondaryLightAccent: '#d1d7df',
        anchorLight: '#0068ca',

        // Basic Colors Accent
        primaryDark: '#003097', // #0a439a
        greyAccent: '#e8eaed',

        // Alerts
        success: '#00C8B5', // #00C8B5
        warning: '#FFB300', // #FFB300
        danger: '#EC2E53', // #EC2E53
        error: '#EC2E53', // #EC2E53
        info: '#006c9c', // #0092E8 #0288D1

        // Alerts Text
        successText: '#00c8b5',
        warningText: '#ffab00',
        dangerText: '#d32f2f',
        errorText: '#d32f2f',
        infoText: '#01579b',

        // Alerts Light
        successLight: '#dcf6ee',
        warningLight: '#faf0dd',
        dangerLight: '#ffeff2',
        errorLight: '#ffe8ec',
        infoLight: '#c3e7ff',

        // Colors
        black: '#010101',
        blueDark: '#202124',

        // Backgrounds
        dark: '#070915', // #070915 #040f2a
        darkGrey: '#070b10',
        darkAccent: '#253756',

        gray: '#f0f0f0', // #f1f3f4
        grayAccent: '#eff3f5',

        // Backgrounds Light
        darkLight: '#0e111f', // #0e111f #061437
        grayLight: '#F4F5F7',
        grayAccentLight: '#eff3f5',
        neutralGrayLight: '#9ba6c1',
        darkAccentLight: '#253756'
      }
    }
  }
}

export default new Vuetify(opts)
