import Vue from 'vue'
import VueRouter from 'vue-router'
import AppContent from '../views/AppContent.vue'
import IndexView from '../views/IndexView.vue'
import ViewContent from '../views/ViewContent.vue'
import SingleViewContent from '../views/SingleViewContent.vue'

// import MaintanceLogCreateContent from '../views/maintance-logs/MaintanceLogCreateContent.vue'
// import MaintanceLogEditContent from '../views/maintance-logs/MaintanceLogEditContent.vue'
import CheckListCreateContent from '../views/check-lists/CheckListCreateContent.vue'
import CheckListEditContent from '../views/check-lists/CheckListEditContent.vue'

import CondominiumDetailsContent from '../views/manage/condominiums/CondominiumDetailsContent'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'IndexView',
    component: IndexView,
    meta: {
      title: 'PACH Supervisión'
    }
  },
  {
    path: '/auth',
    name: 'AuthContent',
    component: SingleViewContent,
    children: [
      {
        path: '/auth/login',
        name: 'LoginContent',
        component: SingleViewContent,
        children: [
          {
            path: '/',
            name: 'LoginView',
            component: () => import(/* webpackChunkName: "auth--login" */ '../views/auth/login/LoginView.vue'),
            meta: {
              title: 'Login',
              auth: true
            }
          }
        ]
      },
      {
        path: '/auth/logout',
        name: 'LogoutContent',
        component: SingleViewContent,
        children: [
          {
            path: '/',
            name: 'LogoutView',
            component: () => import(/* webpackChunkName: "auth--logout" */ '../views/auth/logout/LogoutView.vue'),
            meta: {
              title: 'Logout'
            }
          }
        ]
      },
      {
        path: '/auth/reset-passwd',
        name: 'ResetPasswdContent',
        component: SingleViewContent,
        children: [
          {
            path: '/',
            name: 'ResetPasswdView',
            component: () => import(/* webpackChunkName: "auth--reset-passwd" */ '../views/auth/reset-passwd/ResetPasswdView.vue'),
            meta: {
              title: 'Restablecer contraseña'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/',
    name: 'AppContent',
    component: AppContent,
    redirect: '/check-lists',
    children: [
      // {
      //   path: '/dashboard',
      //   name: 'DashboardContent',
      //   component: ViewContent,
      //   children: [
      //     {
      //       path: '/',
      //       name: 'DashboardView',
      //       component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/DashboardView.vue'),
      //       meta: {
      //         title: 'Dashboard'
      //       }
      //     }
      //   ]
      // },
      {
        path: '/check-lists',
        name: 'CheckListsContent',
        component: ViewContent,
        children: [
          {
            path: '/',
            name: 'CheckListsView',
            component: () => import(/* webpackChunkName: "check-lists" */ '../views/check-lists/CheckListsView.vue'),
            meta: {
              title: 'Listas de verificación'
            }
          },
          // {
          //   path: '/check-lists/create',
          //   name: 'CheckListCreateView',
          //   component: () => import(/* webpackChunkName: "check-lists--create" */ '../views/check-lists/CheckListCreateView.vue'),
          //   meta: {
          //     title: 'Crear lista'
          //   }
          // },
          // {
          //   path: '/check-lists/edit/:id?',
          //   name: 'CheckListEditView',
          //   component: () => import(/* webpackChunkName: "check-lists--edit" */ '../views/check-lists/CheckListEditView.vue'),
          //   meta: {
          //     title: 'Modificar lista'
          //   }
          // },
          {
            path: '/check-lists/create/:condominium?',
            name: 'CheckListCreateContent',
            component: CheckListCreateContent,
            children: [
              {
                path: '/',
                name: 'CheckListCreateView',
                component: () => import(/* webpackChunkName: "check-lists--create" */ '../views/check-lists/CheckListCreateView.vue'),
                meta: {
                  title: 'Crear lista'
                }
              },
              {
                path: '/check-lists/create/answers/:id',
                name: 'CheckListCreateAnswersView',
                component: () => import(/* webpackChunkName: "check-lists--create-answers" */ '../views/check-lists/CheckListCreateAnswersView.vue'),
                meta: {
                  title: 'Crear lista'
                }
              },
              {
                path: '/check-lists/create/tracking/:id',
                name: 'CheckListCreateTrackingView',
                component: () => import(/* webpackChunkName: "check-lists--create-tracking" */ '../views/check-lists/CheckListCreateTrackingView.vue'),
                meta: {
                  title: 'Crear lista'
                }
              }
            ]
          },
          {
            path: '/check-lists/edit',
            name: 'CheckListEditContent',
            component: CheckListEditContent,
            children: [
              {
                path: '/check-lists/edit/:id',
                name: 'CheckListEditView',
                component: () => import(/* webpackChunkName: "check-lists--edit" */ '../views/check-lists/CheckListEditView.vue'),
                meta: {
                  title: 'Modificar lista'
                }
              },
              {
                path: '/check-lists/edit/answers/:id',
                name: 'CheckListEditAnswersView',
                component: () => import(/* webpackChunkName: "check-lists--edit-answers" */ '../views/check-lists/CheckListEditAnswersView.vue'),
                meta: {
                  title: 'Modificar lista'
                }
              },
              {
                path: '/check-lists/edit/tracking/:id',
                name: 'CheckListEditTrackingView',
                component: () => import(/* webpackChunkName: "check-lists--edit-tracking" */ '../views/check-lists/CheckListEditTrackingView.vue'),
                meta: {
                  title: 'Modificar lista'
                }
              }
            ]
          },
          {
            path: '/check-lists/view/:id',
            name: 'CheckListView',
            component: () => import(/* webpackChunkName: "check-lists--view" */ '../views/check-lists/CheckListDetailsView.vue'),
            meta: {
              title: 'Detalle de la lista'
            }
          }
        ]
      },
      {
        path: '/maintance-logs',
        name: 'MaintanceLogsContent',
        component: ViewContent,
        children: [
          {
            path: '/',
            name: 'MaintanceLogsView',
            component: () => import(/* webpackChunkName: "maintance-logs" */ '../views/maintance-logs/MaintanceLogsView.vue'),
            meta: {
              title: 'Bitácoras de mantenimiento'
            }
          },
          {
            path: '/maintance-logs/create/:condominium?',
            name: 'MaintanceLogCreateView',
            component: () => import(/* webpackChunkName: "maintance-logs--create" */ '../views/maintance-logs/MaintanceLogCreateView.vue'),
            meta: {
              title: 'Crear bitácora'
            }
          },
          {
            path: '/maintance-logs/edit/:id?',
            name: 'MaintanceLogEditView',
            component: () => import(/* webpackChunkName: "maintance-logs--edit" */ '../views/maintance-logs/MaintanceLogEditView.vue'),
            meta: {
              title: 'Modificar bitácora'
            }
          },
          // {
          //   path: '/maintance-logs/create',
          //   name: 'MaintanceLogCreateContent',
          //   component: MaintanceLogCreateContent,
          //   children: [
          //     {
          //       path: '/',
          //       name: 'MaintanceLogCreateView',
          //       component: () => import(/* webpackChunkName: "maintance-logs--create" */ '../views/maintance-logs/MaintanceLogCreateView.vue'),
          //       meta: {
          //         title: 'Crear bitácora'
          //       }
          //     },
          //     {
          //       path: '/maintance-logs/create/complete',
          //       name: 'MaintanceLogCreateView',
          //       component: () => import(/* webpackChunkName: "maintance-logs--create-complete" */ '../views/maintance-logs/MaintanceLogCreateCompleteView.vue'),
          //       meta: {
          //         title: 'Crear bitácora'
          //       }
          //     }
          //   ]
          // },
          // {
          //   path: '/maintance-log/edit',
          //   name: 'MaintanceLogEditContent',
          //   component: MaintanceLogEditContent,
          //   children: [
          //     {
          //       path: '/',
          //       name: 'MaintanceLogEditView',
          //       component: () => import(/* webpackChunkName: "maintance-logs--edit" */ '../views/maintance-logs/MaintanceLogEditView.vue'),
          //       meta: {
          //         title: 'Modificar bitácora'
          //       }
          //     },
          //     {
          //       path: '/maintance-log/edit/complete',
          //       name: 'MaintanceLogEditCompleteView',
          //       component: () => import(/* webpackChunkName: "maintance-logs--edit-complete" */ '../views/maintance-logs/MaintanceLogEditCompleteView.vue'),
          //       meta: {
          //         title: 'Modificar bitácora'
          //       }
          //     }
          //   ]
          // },
          {
            path: '/maintance-logs/view/:id?',
            name: 'MaintanceLogDetailsView',
            component: () => import(/* webpackChunkName: "maintance-logs--view" */ '../views/maintance-logs/MaintanceLogDetailsView.vue'),
            meta: {
              title: 'Detalle de la bitácora'
            }
          }
        ]
      },
      {
        path: '/maintances',
        name: 'MaintancesContent',
        component: ViewContent,
        children: [
          {
            path: '/',
            name: 'MaintancesView',
            component: () => import(/* webpackChunkName: "maintances" */ '../views/maintances/MaintancesView.vue'),
            meta: {
              title: 'Mantenimientos'
            }
          },
          {
            path: '/maintances/create',
            name: 'MaintanceCreateView',
            component: () => import(/* webpackChunkName: "maintances--create" */ '../views/maintances/MaintanceCreateView.vue'),
            meta: {
              title: 'Crear mantenimiento'
            }
          },
          {
            path: '/maintances/edit',
            name: 'MaintanceEditView',
            component: () => import(/* webpackChunkName: "maintances--edit" */ '../views/maintances/MaintanceEditView.vue'),
            meta: {
              title: 'Modificar mantenimiento'
            }
          },
          {
            path: '/maintances/view',
            name: 'MaintancDetailsView',
            component: () => import(/* webpackChunkName: "maintances--view" */ '../views/maintances/MaintanceDetailsView.vue'),
            meta: {
              title: 'Detalle del mantenimiento'
            }
          }
        ]
      },
      {
        path: '/threads',
        name: 'ThreadsContent',
        component: ViewContent,
        children: [
          {
            path: '/',
            name: 'ThreadsView',
            component: () => import(/* webpackChunkName: "threads" */ '../views/threads/ThreadsView.vue'),
            meta: {
              title: 'Indicencias'
            }
          },
          {
            path: '/threads/create',
            name: 'ThreadCreateView',
            component: () => import(/* webpackChunkName: "threads--create" */ '../views/threads/ThreadCreateView.vue'),
            meta: {
              title: 'Crear incidencia'
            }
          },
          {
            path: '/threads/edit',
            name: 'ThreadEditView',
            component: () => import(/* webpackChunkName: "threads--edit" */ '../views/threads/ThreadEditView.vue'),
            meta: {
              title: 'Modificar incidencia'
            }
          },
          {
            path: '/threads/view',
            name: 'ThreadDetailsView',
            component: () => import(/* webpackChunkName: "threads--view" */ '../views/threads/ThreadDetailsView.vue'),
            meta: {
              title: 'Detalle de la incidencia'
            }
          }
        ]
      },
      {
        path: '/stadistics',
        name: 'StadisticsContent',
        component: ViewContent,
        children: [
          {
            path: '/',
            name: 'StadisticsView',
            component: () => import(/* webpackChunkName: "stadistics" */ '../views/stadistics/StadisticsView.vue'),
            meta: {
              title: 'Estadísticas'
            }
          }
        ]
      },
      {
        path: '/manage',
        name: 'ManageContent',
        component: ViewContent,
        children: [
          {
            path: '/',
            name: 'ManageView',
            component: () => import(/* webpackChunkName: "manage" */ '../views/manage/ManageView.vue'),
            meta: {
              title: 'Administración'
            }
          },
          {
            path: '/manage/check-lists',
            name: 'ManageCheckListsContent',
            component: ViewContent,
            children: [
              {
                path: '/',
                name: 'ManageCheckListsView',
                component: () => import(/* webpackChunkName: "manage--check-lists" */ '../views/manage/check-lists/ManageCheckListsView.vue'),
                meta: {
                  title: 'Administrar listas de vericación'
                }
              },
              {
                path: '/manage/check-lists/groups',
                name: 'ManageGroupsContent',
                component: ViewContent,
                children: [
                  {
                    path: '/',
                    name: 'ManageGroupsView',
                    component: () => import(/* webpackChunkName: "manage--groups" */ '../views/manage/check-lists/groups/GroupsView.vue'),
                    meta: {
                      title: 'Grupos'
                    }
                  },
                  {
                    path: '/manage/check-lists/groups/create',
                    name: 'ManageGroupCreateView',
                    component: () => import(/* webpackChunkName: "manage--group-create" */ '../views/manage/check-lists/groups/GroupCreateView.vue'),
                    meta: {
                      title: 'Nuevo grupo'
                    }
                  },
                  {
                    path: '/manage/check-lists/groups/edit/:id',
                    name: 'ManageGroupEditView',
                    component: () => import(/* webpackChunkName: "manage--group-edit" */ '../views/manage/check-lists/groups/GroupEditView.vue'),
                    meta: {
                      title: 'Modificar grupo'
                    }
                  },
                  {
                    path: '/manage/check-lists/groups/options/:group',
                    name: 'ManageOptionsContent',
                    component: ViewContent,
                    children: [
                      {
                        path: '/',
                        name: 'ManageOptionsView',
                        component: () => import(/* webpackChunkName: "manage--options" */ '../views/manage/check-lists/options/OptionsView.vue'),
                        meta: {
                          title: 'Opciones'
                        }
                      },
                      {
                        path: '/manage/check-lists/groups/options/create/:group',
                        name: 'ManageOptionCreateView',
                        component: () => import(/* webpackChunkName: "manage--option-create" */ '../views/manage/check-lists/options/OptionCreateView.vue'),
                        meta: {
                          title: 'Nueva opción'
                        }
                      },
                      {
                        path: '/manage/check-lists/groups/options/edit/:group/:id',
                        name: 'ManageOptionEditView',
                        component: () => import(/* webpackChunkName: "manage--option-edit" */ '../views/manage/check-lists/options/OptionEditView.vue'),
                        meta: {
                          title: 'Modificar opción'
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: '/manage/condominiums',
            name: 'CondominiumsContent',
            component: ViewContent,
            children: [
              {
                path: '/',
                name: 'CondominiumsView',
                component: () => import(/* webpackChunkName: "manage--condominiums" */ '../views/manage/condominiums/CondominiumsView.vue'),
                meta: {
                  title: 'Condominios'
                }
              },
              {
                path: '/manage/condominiums/create',
                name: 'CondominiumCreateView',
                component: () => import(/* webpackChunkName: "manage--condominiums-create" */ '../views/manage/condominiums/CondominiumCreateView.vue'),
                meta: {
                  title: 'Nuevo condominio'
                }
              },
              {
                path: '/manage/condominiums/edit/:id',
                name: 'CondominiumEditView',
                component: () => import(/* webpackChunkName: "manage--condominiums-edit" */ '../views/manage/condominiums/CondominiumEditView.vue'),
                meta: {
                  title: 'Modificar condominio'
                }
              },
              {
                path: '/manage/condominiums/view/:id',
                name: 'CondominiumDetailsContent',
                component: CondominiumDetailsContent,
                children: [
                  // {
                  //   path: '/',
                  //   name: 'CondominiumDetailsView',
                  //   component: () => import(/* webpackChunkName: "manage--condominiums-view-details" */ '../views/manage/condominiums/CondominiumDetailsView.vue'),
                  //   meta: {
                  //     title: 'Detalle de condominio'
                  //   }
                  // },
                  {
                    path: '/',
                    name: 'CondominiumDetailsCheckListsView',
                    component: () => import(/* webpackChunkName: "manage--condominiums-view-check-lists" */ '../views/manage/condominiums/CondominiumCheckListsView.vue'),
                    meta: {
                      title: 'Detalle de condominio'
                    }
                  },
                  {
                    path: '/manage/condominiums/view/maintance-logs/:id',
                    name: 'CondominiumDetailsMaintanceLogsView',
                    component: () => import(/* webpackChunkName: "manage--condominiums-view-maintance-logs" */ '../views/manage/condominiums/CondominiumMaintanceLogsView.vue'),
                    meta: {
                      title: 'Detalle de condominio'
                    }
                  }
                ]
              },
              {
                path: '/manage/condominiums/configure',
                name: 'CondominiumConfigureContent',
                component: ViewContent,
                children: [
                  {
                    path: '/',
                    name: 'CondominiumConfigureView',
                    component: () => import(/* webpackChunkName: "manage--condominiums-configure-check-list" */ '../views/manage/condominiums/CondominiumConfigureView.vue'),
                    meta: {
                      title: 'Configurar condominio'
                    }
                  },
                  {
                    path: '/manage/condominiums/configure/check-list/:id',
                    name: 'CondominiumConfigureCheckListView',
                    component: () => import(/* webpackChunkName: "manage--condominiums-configure-check-list" */ '../views/manage/condominiums/CondominiumConfigureCheckListView.vue'),
                    meta: {
                      title: 'Configurar lista de verificación'
                    }
                  }
                ]
              }
            ]
          },
          {
            path: '/manage/companies',
            name: 'CompaniesContent',
            component: ViewContent,
            children: [
              {
                path: '/',
                name: 'CompaniesView',
                component: () => import(/* webpackChunkName: "manage--companies" */ '../views/manage/companies/CompaniesView.vue'),
                meta: {
                  title: 'Empresas'
                }
              },
              {
                path: '/manage/companies/create',
                name: 'CompanyCreateView',
                component: () => import(/* webpackChunkName: "manage--company-create" */ '../views/manage/companies/CompanyCreateView.vue'),
                meta: {
                  title: 'Nueva empresa'
                }
              },
              {
                path: '/manage/companies/edit/:id',
                name: 'CompanyEditView',
                component: () => import(/* webpackChunkName: "manage--company-edit" */ '../views/manage/companies/CompanyEditView.vue'),
                meta: {
                  title: 'Modificar empresa'
                }
              }
            ]
          },
          {
            path: '/manage/statuses',
            name: 'StatusesContent',
            component: ViewContent,
            children: [
              {
                path: '/',
                name: 'StatusesView',
                component: () => import(/* webpackChunkName: "manage--statuses" */ '../views/manage/statuses/StatusesView.vue'),
                meta: {
                  title: 'Estados'
                }
              },
              {
                path: '/manage/statuses/create',
                name: 'StatuseCreateView',
                component: () => import(/* webpackChunkName: "manage--statuse-create" */ '../views/manage/statuses/StatuseCreateView.vue'),
                meta: {
                  title: 'Crear estado'
                }
              },
              {
                path: '/manage/statuses/edit',
                name: 'StatuseEditView',
                component: () => import(/* webpackChunkName: "manage--statuse-edit" */ '../views/manage/statuses/StatuseEditView.vue'),
                meta: {
                  title: 'Modificar estado'
                }
              }
            ]
          },
          {
            path: '/manage/business',
            name: 'ManageBusinessContent',
            component: ViewContent,
            children: [
              {
                path: '/',
                name: 'ManageBusinessView',
                component: () => import(/* webpackChunkName: "manage--business" */ '../views/manage/business/BusinessView.vue'),
                meta: {
                  title: 'Empresa'
                }
              },
              {
                path: '/manage/business/info',
                name: 'ManageBusinessInfoView',
                component: () => import(/* webpackChunkName: "manage--business-info" */ '../views/manage/business/info/BusinessInfoView.vue'),
                meta: {
                  title: 'Información de la empresa'
                }
              },
              {
                path: '/manage/business/departments',
                name: 'ManageDepartmentsContent',
                component: ViewContent,
                children: [
                  {
                    path: '/',
                    name: 'DepartamentsView',
                    component: () => import(/* webpackChunkName: "manage--business-departments" */ '../views/manage/business/departments/DepartmentsView.vue'),
                    meta: {
                      title: 'Departamentos'
                    }
                  },
                  {
                    path: '/manage/business/departments/create',
                    name: 'ManageDepartmentCreateView',
                    component: () => import(/* webpackChunkName: "manage--business-department-create" */ '../views/manage/business/departments/DepartmentCreateView.vue'),
                    meta: {
                      title: 'Crear departamento'
                    }
                  },
                  {
                    path: '/manage/business/departments/edit/:id',
                    name: 'ManageDepartmentEditView',
                    component: () => import(/* webpackChunkName: "manage--business-department-edit" */ '../views/manage/business/departments/DepartmentEditView.vue'),
                    meta: {
                      title: 'Modificar departamento'
                    }
                  }
                ]
              },
              {
                path: '/manage/business/supervisors',
                name: 'ManageSupervisorsContent',
                component: ViewContent,
                children: [
                  {
                    path: '/',
                    name: 'ManageSupervisorsView',
                    component: () => import(/* webpackChunkName: "manage--business-supervisors" */ '../views/manage/business/supervisors/SupervisorsView.vue'),
                    meta: {
                      title: 'Supervisores'
                    }
                  },
                  {
                    path: '/manage/business/supervisors/create',
                    name: 'ManageSupervisorCreateView',
                    component: () => import(/* webpackChunkName: "manage--business-supervisor-create" */ '../views/manage/business/supervisors/SupervisorCreateView.vue'),
                    meta: {
                      title: 'Crear supervisor'
                    }
                  },
                  {
                    path: '/manage/business/supervisors/edit/:id',
                    name: 'ManageSupervisorEditView',
                    component: () => import(/* webpackChunkName: "manage--business-supervisor-edit" */ '../views/manage/business/supervisors/SupervisorEditView.vue'),
                    meta: {
                      title: 'Modificar supervisor'
                    }
                  },
                  {
                    path: '/manage/business/supervisors/view/:id',
                    name: 'ManageSupervisorDetailsView',
                    component: () => import(/* webpackChunkName: "manage--business-supervisor-details" */ '../views/manage/business/supervisors/SupervisorDetailsView.vue'),
                    meta: {
                      title: 'Información del supervisor'
                    }
                  }
                ]
              },
              {
                path: '/manage/business/team',
                name: 'ManageTeamContent',
                component: ViewContent,
                children: [
                  {
                    path: '/',
                    name: 'ManageTeamView',
                    component: () => import(/* webpackChunkName: "manage--business-team" */ '../views/manage/business/team/UsersView.vue'),
                    meta: {
                      title: 'Equipo'
                    }
                  },
                  {
                    path: '/manage/business/team/create',
                    name: 'ManageUserCreateView',
                    component: () => import(/* webpackChunkName: "manage--business-user-create" */ '../views/manage/business/team/UserCreateView.vue'),
                    meta: {
                      title: 'Crear usuario'
                    }
                  },
                  {
                    path: '/manage/business/team/edit/:id',
                    name: 'ManageUserEditView',
                    component: () => import(/* webpackChunkName: "manage--business-user-edit" */ '../views/manage/business/team/UserEditView.vue'),
                    meta: {
                      title: 'Modificar usuario'
                    }
                  },
                  {
                    path: '/manage/business/team/view/:id',
                    name: 'ManageUserDetailsView',
                    component: () => import(/* webpackChunkName: "manage--business-user-details" */ '../views/manage/business/team/UserDetailsView.vue'),
                    meta: {
                      title: 'Información del usuario'
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: '/settings',
        name: 'SettingsContent',
        component: ViewContent,
        redirect: '/settings/general',
        children: [
          {
            path: '/',
            name: 'SettingsView',
            component: () => import(/* webpackChunkName: "settings" */ '../views/settings/SettingsView.vue'),
            meta: {
              title: 'Configuración'
            }
          },
          {
            path: '/settings/general',
            name: 'GeneralSettingsView',
            component: () => import(/* webpackChunkName: "settings--general" */ '../views/settings/general/GeneralSettingsView.vue'),
            meta: {
              title: 'Configuración'
            }
          },
          {
            path: '/settings/account',
            name: 'AccountSettingsContent',
            component: ViewContent,
            children: [
              {
                path: '/',
                name: 'AccountSettingsView',
                component: () => import(/* webpackChunkName: "settings--account" */ '../views/settings/account/AccountSettingsView.vue'),
                meta: {
                  title: 'Configuración de la cuenta'
                }
              }
            ]
          }
        ]
      },
      {
        path: '/account',
        name: 'AccountContent',
        component: ViewContent,
        children: [
          {
            path: '/',
            name: 'AccountView',
            component: () => import(/* webpackChunkName: "account" */ '../views/account/AccountView.vue'),
            meta: {
              title: 'Mi cuenta'
            }
          },
          {
            path: '/account/profile',
            name: 'ProfileView',
            component: () => import(/* webpackChunkName: "account--profile" */ '../views/account/ProfileView.vue'),
            meta: {
              title: 'Mi perfil'
            }
          }
        ]
      },
      {
        path: '/help',
        name: 'HelpContent',
        component: ViewContent,
        children: [
          {
            path: '/',
            name: 'HelpView',
            component: () => import(/* webpackChunkName: "help" */ '../views/help/HelpView.vue'),
            meta: {
              title: 'Ayuda'
            }
          },
          {
            path: '/help/get-support',
            name: 'GetSupport',
            component: () => import(/* webpackChunkName: "help--get-support" */ '../views/help/GetSupportView.vue'),
            meta: {
              title: 'Obtener asistencia'
            }
          },
          {
            path: '/help/feedback',
            name: 'FeedBack',
            component: () => import(/* webpackChunkName: "help--feedback" */ '../views/help/FeedBackView.vue'),
            meta: {
              title: 'Enviar comentarios'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/welcome',
    name: 'WelcomeView',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/welcome/WelcomeView.vue'),
    meta: {
      title: 'Le damos la bienvenida'
    }
  },
  {
    path: '*',
    name: 'ErrorView',
    component: () => import(/* webpackChunkName: "error" */ '../views/ErrorView.vue'),
    meta: {
      title: 'Oops'
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  saveScrollPosition: true,
  transitionOnLoad: true,
  base: process.env.BASE_URL,
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
