<template>
  <div>
    <div
      class="app-section">
      <v-container
        fluid>
        <v-row
          align="center">
          <v-col
            xl="7" lg="7" md="7" sm="12"
            cols="12">
            <div class="app-section--text with-back">
              <v-btn
                href="#/manage/condominiums"
                class="is-back"
                small
                icon
                fab>
                <v-icon>
                  arrow_back
                </v-icon>
              </v-btn>
              <span
                class="text-h5 font-weight-semibold mb-0">
                Detalle de condominio
              </span>
            </div>
          </v-col>
          <v-col
            class="text-center text-sm-right"
            xl="5" lg="5" md="5"
            cols="12">
            <v-tooltip
              :open-on-click="false"
              :open-on-focus="false"
              z-index="6"
              bottom>
              <template v-slot:[`activator`]="{ on, attrs }">
                <v-btn
                  :href="'#/manage/condominiums/edit/' + $route.params.id"
                  :color="!$vuetify.theme.dark ? '' : ''"
                  class="mr-3"
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  small
                  fab>
                  <v-icon
                    size="22">
                    edit
                  </v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip
              :open-on-click="false"
              :open-on-focus="false"
              z-index="6"
              bottom>
              <template v-slot:[`activator`]="{ on, attrs }">
                <v-btn
                  :href="'#/manage/condominiums/configure/check-list/' + $route.params.id"
                  :color="!$vuetify.theme.dark ? '' : ''"
                  class="mr-3"
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  small
                  fab>
                  <v-icon
                    size="22">
                    add_task
                  </v-icon>
                </v-btn>
              </template>
              <span>Configurar lista</span>
            </v-tooltip>
            <v-tooltip
              :open-on-click="false"
              :open-on-focus="false"
              z-index="6"
              bottom>
              <template v-slot:[`activator`]="{ on, attrs }">
                <v-btn
                  :href="'#/maintance-logs/create/' + $route.params.id"
                  :color="!$vuetify.theme.dark ? '' : ''"
                  class="mr-3"
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  small
                  fab>
                  <v-icon
                    size="22">
                    library_add
                  </v-icon>
                </v-btn>
              </template>
              <span>Nueva bitácora</span>
            </v-tooltip>
            <v-tooltip
              :open-on-click="false"
              :open-on-focus="false"
              z-index="6"
              bottom>
              <template v-slot:[`activator`]="{ on, attrs }">
                <v-btn
                  :href="'#/check-lists/create/' + $route.params.id"
                  :color="!$vuetify.theme.dark ? '' : ''"
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  small
                  fab>
                  <v-icon
                    class="material-icons-outlined"
                    size="22">
                    checklist
                  </v-icon>
                </v-btn>
              </template>
              <span>Nueva lista</span>
            </v-tooltip>
            <!-- <v-speed-dial
              v-model="btnOptions"
              transition="slide-y-reverse-transition"
              direction="top"
              bottom
              large
              right
              fixed>
              <template v-slot:activator>
                <v-btn
                  v-model="btnOptions"
                  color="accent"
                  large
                  fab>
                  <v-icon
                    v-if="btnOptions"
                    color="grey lighten-4">
                    close
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey lighten-4">
                    settings
                  </v-icon>
                </v-btn>
              </template>
              <v-tooltip
                :open-on-click="false"
                :open-on-focus="false"
                z-index="6"
                left>
                <template v-slot:[`activator`]="{ on, attrs }">
                  <v-btn
                    :href="'#/maintance-logs/create/' + $route.params.id"
                    :color="!$vuetify.theme.dark ? 'white' : ''"
                    elevation="3"
                    v-bind="attrs"
                    v-on="on"
                    small
                    fab>
                    <v-icon
                      size="22">
                      library_add
                    </v-icon>
                  </v-btn>
                </template>
                <span>Nueva bitácora</span>
              </v-tooltip>
              <v-tooltip
                :open-on-click="false"
                :open-on-focus="false"
                z-index="6"
                left>
                <template v-slot:[`activator`]="{ on, attrs }">
                  <v-btn
                    :href="'#/check-lists/create/' + $route.params.id"
                    :color="!$vuetify.theme.dark ? 'white' : ''"
                    elevation="2"
                    v-bind="attrs"
                    v-on="on"
                    small
                    fab>
                    <v-icon
                      class="material-icons-outlined"
                      size="22">
                      checklist
                    </v-icon>
                  </v-btn>
                </template>
                <span>Nueva lista</span>
              </v-tooltip>
              <v-tooltip
                :open-on-click="false"
                :open-on-focus="false"
                z-index="6"
                left>
                <template v-slot:[`activator`]="{ on, attrs }">
                  <v-btn
                    :href="'#/manage/condominiums/configure/check-list/' + $route.params.id"
                    :color="!$vuetify.theme.dark ? 'white' : ''"
                    elevation="2"
                    v-bind="attrs"
                    v-on="on"
                    small
                    fab>
                    <v-icon
                      size="22">
                      add_task
                    </v-icon>
                  </v-btn>
                </template>
                <span>Configurar lista</span>
              </v-tooltip>
              <v-tooltip
                :open-on-click="false"
                :open-on-focus="false"
                z-index="6"
                left>
                <template v-slot:[`activator`]="{ on, attrs }">
                  <v-btn
                    :href="'#/manage/condominiums/edit/' + $route.params.id"
                    :color="!$vuetify.theme.dark ? 'white' : ''"
                    elevation="2"
                    v-bind="attrs"
                    v-on="on"
                    small
                    fab>
                    <v-icon
                      size="22">
                      edit
                    </v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </v-speed-dial> -->
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container
      fluid>
      <span
        class="text-h4 font-weight-bold text-center d-block mb-1">
        {{ condominium.name }}
      </span>
      <span
        class="text-h6 font-weight-semibold text-center grey--text text--darken-2 d-block mb-10 mb-sm-13">
        {{ condominium.prefix }}
      </span>
      <v-tabs
        :show-arrows="$vuetify.breakpoint.mobile"
        background-color="transparent"
        slider-size="3">
        <!-- <v-tab
          :class="$route.matched.some(({ name }) => name === 'CondominiumDetailsView') ? 'v-tab--active' : ''"
          :to="'/manage/condominiums/view/' + $route.params.id"
          link>
          Información
        </v-tab> -->
        <v-tab
          :class="$route.matched.some(({ name }) => name === 'CondominiumCheckListsView') ? 'v-tab--active' : ''"
          :to="'/manage/condominiums/view/' + $route.params.id"
          link>
          Check lists
        </v-tab>
        <v-tab
          :class="$route.matched.some(({ name }) => name === 'CondominiumMaintanceLogsView') ? 'v-tab--active' : ''"
          :to="'/manage/condominiums/view/maintance-logs/' + $route.params.id"
          link>
          Bitácoras
        </v-tab>
      </v-tabs>
      <v-divider
        class="mb-10 mb-sm-13">
      </v-divider>
    </v-container>
    <transition name="fade">
      <router-view
        @condominium="setName"
        @snack="toggleSnack"
        @loading="toggleLoading"></router-view>
    </transition>
    <!-- Start Loading -->
    <!-- <Loading
      :show="loading"
      ref="loading">
    </Loading> -->
    <!-- End Loading -->
    <!-- Start Ajax Alert -->
    <!-- <Snack
      :color="snack.color"
      :title="snack.title"
      :text="snack.text"
      :icon="snack.icon"
      ref="snack">
    </Snack> -->
    <!-- End Ajax Alert -->
  </div>
</template>

<script>
// Plugins
// import axios from 'axios'
import veevalidate from '@/plugins/vee-validate'

// Mixins
import { defines, layout, theme } from '@/mixins/config'

// Components
// import Loading from '@/components/LoadingComponent'
// import Snack from '@/components/SnackComponent'

export default {
  name: 'CondominiumDetailsView',
  mixins: [defines, layout, theme, veevalidate],
  components: { },
  data: () => ({
    btnOptions: false,
    condominium: {
      name: '',
      prefix: ''
    },
    stadistics: {
      allTime: 0,
      lastDays: 0,
      inValidating: 0,
      pending: 0
    },
    saving: false,
    loading: false,
    snack: {
      color: '',
      title: '',
      icon: '',
      text: ''
    }
  }),
  methods: {
    setName (value) {
      this.condominium.name = value.name
      this.condominium.prefix = value.prefix
    },

    toggleLoading (loading) {
      this.$emit('loading', loading)
    },

    toggleSnack (snack) {
      this.$emit('snack', snack)
    }
  },
  watch: { },
  computed: { },
  mounted () { },
  created () { },
  destroyed () { }
}

</script>

<style>

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .27s;
}

.fade-enter-active {
  transition-delay: .27s;
}

.fade-enter, .fade-leave-active {
  opacity: 0;
  /* transform: translateX(10px); */
}

</style>
