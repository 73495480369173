<template>
  <v-snackbar
    v-model="visibility"
    :timeout="timeout"
    :max-width="$vuetify.breakpoint.mobile ? '97%' : ''"
    content-class="rounded-lg">
    <!-- <strong v-html="title"></strong> -->
    <p v-html="text" class="mb-0"></p>
    <template v-slot:[`action`]="{ attrs }">
      <v-btn
        @click="visibility = !visibility"
        v-bind="attrs"
        icon>
        <v-icon>
          clear
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'SnackComponent',
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    visibility: false,
    timeout: 5000
  }),
  methods: {
    toggle () {
      this.visibility = !this.visibility
    }
  }
}

</script>
