<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant-width="miniWidth"
    :color="!$vuetify.theme.dark ? 'dark' : ''"
    :width="$vuetify.breakpoint.mobile ? '290px' : width"
    :floating="$vuetify.breakpoint.mobile ? true : true"
    :temporary="$vuetify.breakpoint.mobile ? true : false"
    :permanent="$vuetify.breakpoint.mobile ? false : true"
    :class="mini ? 'app-sidebar' : 'app-sidebar expanded'"
    :mini-variant="$vuetify.breakpoint.mobile ? false : mini"
    :hide-overlay="$vuetify.breakpoint.mobile ? false : true"
    :expand-on-hover="$vuetify.breakpoint.mobile ? false : expandOnHover"
    mobile-breakpoint="600"
    overlay-opacity="0.6"
    overlay-color="black"
    tag="aside"
    clipped-left
    fixed
    dark
    app>
    <template v-slot:[`prepend`]>
      <v-toolbar
        v-if="$vuetify.breakpoint.mobile"
        :color="$vuetify.breakpoint.mobile ? '' : ''"
        :height="toolbar.height"
        class="px-0"
        tag="div"
        outlined
        flat>
        <v-toolbar-title>
          <div class="brand d-flex align-center">
            <div
              class="brand--icon">
              <v-img
                :src="!$vuetify.theme.dark ? APP_IMG_BRAND + 'pach.svg' : APP_IMG_BRAND + 'pach-light.svg'"
                width="95px"
                contain></v-img>
            </div>
          </div>
        </v-toolbar-title>
      </v-toolbar>
    </template>
    <!-- <vuescroll
      :ops="ops"> -->
    <v-list
      class="mt-2 mt-sm-2 px-3 px-sm-3"
      dense
      nav>
      <!-- <v-subheader
        class="mt-0">
        <span>Resumen</span>
      </v-subheader>
      <v-list-item
        active-class="primary--text"
        to="/dashboard"
        link>
        <v-list-item-icon>
          <v-icon
            :class="!$route.matched.some(({ name }) => name === 'DashboardView') ? 'material-icons-outlined' : ''">
            grid_view
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Dashboard
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-subheader
        class="pl-4">
        <span>Operación</span>
      </v-subheader>
      <v-list-item
        active-class="primary--text"
        to="/check-lists"
        link>
        <v-list-item-icon>
          <v-icon
            :class="!$route.matched.some(({ name }) => name === 'CheckListsView') ? 'material-icons-outlined' : ''">
            checklist
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Check lists
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        active-class="primary--text"
        to="/maintance-logs"
        link>
        <v-list-item-icon>
          <v-icon
            :class="!$route.matched.some(({ name }) => name === 'MaintanceLogsView') ? 'material-icons-outlined' : ''">
            assignment
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Bitácoras
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-subheader
        v-if="session.role === '1'"
        class="pl-4 mt-0">
        <span>Administrar</span>
      </v-subheader>
      <v-list-item
        v-if="session.role === '1'"
        active-class="primary--text"
        to="/manage/condominiums"
        link>
        <v-list-item-icon>
          <v-icon
            :class="!$route.matched.some(({ name }) => name === 'CondominiumsContent') ? 'material-icons-outlined' : ''">
            apartment
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Condominios
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="session.role === '1'"
        active-class="primary--text"
        to="/manage/companies"
        link>
        <v-list-item-icon>
          <v-icon
            :class="!$route.matched.some(({ name }) => name === 'CompaniesContent') ? 'material-icons-outlined' : ''">
            extension
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Empresas
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group
        v-if="session.role === '1'"
        :value="$route.matched.some(({ name }) => name === 'ManageCheckListsContent')"
        active-class="primary--text"
        no-action>
        <template v-slot:[`activator`]>
          <v-list-item-icon>
            <v-icon
              :class="!$route.matched.some(({ name }) => name === 'ManageCheckLists') ? 'material-icons-outlined' : ''">
              checklist
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Check lists
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          to="/manage/check-lists/groups"
          active-class="primary--text"
          link>
          <v-list-item-content>
            <v-list-item-title>
              Grupos
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-group
        v-if="session.role === '1'"
        :value="$route.matched.some(({ name }) => name === 'ManageBusinessContent')"
        active-class="primary--text"
        no-action>
        <template v-slot:[`activator`]>
          <v-list-item-icon>
            <v-icon
              :class="!$route.matched.some(({ name }) => name === 'ManageBusinessContent') ? 'material-icons-outlined' : ''">
              people
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Empresa
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          active-class="primary--text"
          to="/manage/business/team"
          link>
          <v-list-item-content>
            <v-list-item-title>
              Equipo
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          to="/manage/business/supervisors"
          active-class="primary--text"
          link>
          <v-list-item-content>
            <v-list-item-title>
              Supervisores
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          to="/manage/business/departments"
          active-class="primary--text"
          link>
          <v-list-item-content>
            <v-list-item-title>
              Departamentos
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <!-- </vuescroll> -->
    <template v-slot:[`append`]>
      <v-divider
        v-if="session.role === '1'"
        class="mb-1 mx-3">
      </v-divider>
      <v-list
        v-if="session.role === '1'"
        class="mb-1"
        dense
        nav>
        <v-list-item
          v-if="session.role == '1'"
          to="/settings/general"
          active-class="primary--text"
          link>
          <v-list-item-icon>
            <v-icon
              :class="!$route.matched.some(({ name }) => name === 'GeneralSettingsView') ? 'material-icons-outlined' : ''">
              settings
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Configuración
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- <v-list
        class="px-4 mb-3"
        shaped
        dense>
        <v-list-group
          :value="$route.matched.some(({ name }) => name === 'ManageContent')"
          active-class="primary--text"
          class="mb-1"
          no-action>
          <template v-slot:[`activator`]>
            <v-list-item-icon>
              <v-icon
                :class="!$route.matched.some(({ name }) => name === 'ManageContent') ? 'material-icons-outlined' : ''">
                widgets
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Administración
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            active-class="primary--text"
            to="/manage/condominiums"
            link>
            <v-list-item-content>
              <v-list-item-title>
                Condominios
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            active-class="primary--text"
            to="/manage/companies"
            link>
            <v-list-item-content>
              <v-list-item-title>
                Empresas
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-if="auth.role === '1'"
            :value="$route.matched.some(({ name }) => name === 'ManageCheckListsContent')"
            active-class="primary--text"
            no-action
            sub-group>
            <template v-slot:[`activator`]>
              <v-list-item-content>
                <v-list-item-title>
                  Check lists
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              to="/manage/check-lists/templates"
              active-class="primary--text"
              link>
              <v-list-item-content>
                <v-list-item-title>
                  Plantillas
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              to="/manage/check-lists/groups"
              active-class="primary--text"
              link>
              <v-list-item-content>
                <v-list-item-title>
                  Grupos
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-group
            v-if="auth.role == '1'"
            :value="$route.matched.some(({ name }) => name === 'ManageBusinessContent')"
            active-class="primary--text"
            no-action
            sub-group>
            <template v-slot:[`activator`]>
              <v-list-item-content>
                <v-list-item-title>
                  Empresa
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              active-class="primary--text"
              to="/manage/business/team"
              link>
              <v-list-item-content>
                <v-list-item-title>
                  Equipo
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              to="/manage/business/supervisors"
              active-class="primary--text"
              link>
              <v-list-item-content>
                <v-list-item-title>
                  Supervisores
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              to="/manage/business/departments"
              active-class="primary--text"
              link>
              <v-list-item-content>
                <v-list-item-title>
                  Departamentos
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-group>
        <v-list-item
          v-if="auth.role === '1'"
          to="/settings/general"
          active-class="primary--text"
          link>
          <v-list-item-icon>
            <v-icon
              :class="!$route.matched.some(({ name }) => name === 'GeneralSettingsView') ? 'material-icons-outlined' : ''">
              settings
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Configuración
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->
      <!-- <v-list
        class="py-3"
        nav>
        <v-list-item
          class="v-list-item--active overflow-hidden">
          <v-list-item-avatar>
            <v-avatar
              :color="session.avatar === null || session.avatar === '' ? session.color : ''"
              size="38">
              <img
                v-if="session.avatar !== null && session.avatar !== ''"
                :src="STATIC_IMG_AVATAR + session.avatar + '.svg'">
              <span
                v-else
                class="text-h6 font-weight-regular grey--text text--lighten-4">
                {{ session.avatarText }}
              </span>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              :class="['text-body-2', !$vuetify.theme.dark ? '' : 'grey--text text--lighten-4']">
              {{ session.name }}
            </v-list-item-title>
            <v-list-item-subtitle
              :class="!$vuetify.theme.dark ? '' : 'grey--text text--lighten-1'">
              {{ session.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->
    </template>
  </v-navigation-drawer>
</template>

<script>
// Plugins
// import vuescroll from 'vuescroll'

// Mixins
import { defines, layout, theme, session } from '@/mixins/config'

export default {
  name: 'SidebarComponent',
  mixins: [defines, layout, theme, session],
  components: { },
  props: {
    value: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primaryDark'
    }
  },
  data: () => ({
    app: true,
    width: 260,
    mini: false,
    miniWidth: 80,
    drawer: false,
    expandOnHover: false,
    toolbar: {
      height: 70
    },
    ops: {
      vuescroll: {
        wheelScrollDuration: 250
      },
      scrollPanel: {
        speed: 0,
        scrollingX: false,
        easing: 'easeInQuint',
        initialScrollX: false
      },
      rail: {
        background: 'transparent',
        opacity: 5,
        size: '4px',
        specifyBorderRadius: false
      },
      bar: {
        showDelay: 500,
        onlyShowBarOnScroll: true,
        keepShow: false,
        background: '#ffffff',
        opacity: 0.35,
        hoverStyle: false,
        specifyBorderRadius: false,
        minSize: 0.2,
        size: '4px',
        disable: false
      }
    },
    session: {
      userID: '',
      role: '',
      name: '',
      email: '',
      color: '',
      avatar: '',
      avatarText: ''
    }
  }),
  methods: {
    sessionSync () {
      const auth = JSON.parse(localStorage.getItem('supervision'))
      const sessionDecrypt = JSON.parse(atob(auth.session))

      if (sessionDecrypt) {
        this.session.userID = sessionDecrypt.userID
        this.session.role = sessionDecrypt.role
        this.session.color = sessionDecrypt.color
        this.session.avatar = (sessionDecrypt.avatarID === '') ? null : sessionDecrypt.avatarID
        this.session.avatarText = sessionDecrypt.avatarText
        this.session.name = sessionDecrypt.name
        this.session.email = sessionDecrypt.email
      }
    },

    toggle () {
      if (this.$vuetify.breakpoint.mobile) {
        this.drawer = !this.drawer
      } else {
        const auth = JSON.parse(localStorage.getItem('supervision'))

        this.mini = !this.mini
        this.expandOnHover = !this.expandOnHover

        const supervision = {
          theme: {
            dark: auth.theme.dark,
            color: auth.theme.color,
            accent: auth.theme.accent
          },
          layout: {
            mini: this.mini,
            expandOnHover: this.expandOnHover
          },
          session: auth.session
        }

        localStorage.setItem('supervision', JSON.stringify(supervision))
      }
    }
  },
  watch: { },
  computed: { },
  mounted () {
    if (!this.$vuetify.breakpoint.mobile) {
      const auth = JSON.parse(localStorage.getItem('supervision'))

      // JSON.parse(localStorage.getItem('supervision_layout_mini')) ?? false
      this.mini = auth.layout.mini
      this.expandOnHover = auth.layout.expandOnHover
    }

    this.sessionSync()
  },
  created () {
    // this.getProfile()
  },
  destroyed () { }
}

</script>
